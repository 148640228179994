import React, { useEffect, useState } from 'react';
import Image from '../../components/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import ImageContainer from './ImageContainer';
import LowerPanel from './LowerPanel';
import { useNavigate } from 'react-router-dom';

const ControlPanel = ({
  setPage,
  page,
  isHovered,
  setIsHovered,
  showPicture,
  setShowPicture,
  animationClass,
  setAnimationClass,
}) => {
  const [showPanelMobile, setShowPanelMobile] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const arrowEvent = (event) => {
      if (page > 1 && page < 37) {
        if (event.key === 'ArrowLeft') {
          setShowPicture(false);
          setTimeout(() => {
            setPage((prev) => prev - 1);
          }, 500);
          setAnimationClass('image-piece-show-');
        }
        if (event.key === 'ArrowRight') {
          setShowPicture(false);
          setTimeout(() => {
            setPage((prev) => prev + 1);
          }, 500);
          setAnimationClass('image-piece-show-left-');
        }
      }
    };

    document.addEventListener('keydown', arrowEvent);
    return () => document.removeEventListener('keydown', arrowEvent);
  }, []);

  const copy = () => {
    var text = 'https://designthoughts.superestudio.tv/';
    navigator.clipboard.writeText(text).then(
      function () {
        alert('¡Link al Ebook copiado al portapapeles!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  return (
    <div className="control-screen">
      <div className="mobile-control-panel">
        <div
          className="mobile-pannel-button"
          onClick={() => {
            setShowPanelMobile((prev) => !prev);
          }}
        >
          <FontAwesomeIcon
            icon={faBars}
            className="mobile-control-panel-icon"
          />
        </div>
        {showPanelMobile && (
          <div
            className="mobile-pannel-button"
            onClick={() => {
              navigate('/');
              setPage(1);
            }}
          >
            <Image name="home.png" className="mobile-control-panel-icon" />
          </div>
        )}
        {showPanelMobile && (
          <div
            className="mobile-pannel-button"
            onClick={() => {
              navigate('/gallery');
            }}
          >
            <Image name="grid.png" className="mobile-control-panel-icon" />
          </div>
        )}
        {showPanelMobile && (
          <div
            className="mobile-pannel-button"
            onClick={() => {
              copy();
            }}
          >
            <Image
              name="shareArrow.png"
              className="mobile-control-panel-icon"
            />
          </div>
        )}
      </div>

      <ImageContainer
        page={page}
        setPage={setPage}
        showPicture={showPicture}
        setShowPicture={setShowPicture}
        setAnimationClass={setAnimationClass}
        animationClass={animationClass}
      />
      <div className="half-wrapper">
        <div
          className="half-back"
          onClick={() => {
            setShowPicture(false);
            setTimeout(() => {
              setPage((prev) => prev - 1);
            }, 500);
            setAnimationClass('image-piece-show-');
          }}
        >
          <div
            className="half-back-low"
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          ></div>
        </div>
        <div
          className="half-forward"
          onClick={() => {
            setShowPicture(false);
            setTimeout(() => {
              setPage((prev) => prev + 1);
            }, 500);
            setAnimationClass('image-piece-show-left-');
          }}
        >
          <div
            className="half-forward-low"
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          ></div>
        </div>
      </div>
      {isHovered &&
        page &&
        page !== 14 &&
        page !== 34 &&
        page !== 42 &&
        page !== 61 &&
        page !== 63 && (
          <div
            className="panel-body panel-body-index"
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            <LowerPanel
              setPage={setPage}
              page={page}
              setShowPicture={setShowPicture}
            />
          </div>
        )}
    </div>
  );
};

export default ControlPanel;
