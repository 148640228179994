const pageMap = {
  0: 'page-zero',
  1: 'page-one',
  2: 'cover',
  3: 'index',
  4: 'designing-ideas',
  5: 'chapter0',
  6: 'chapter1',
  7: 'chapter1-1',
  8: 'chapter1-2',
  9: 'chapter1-3',
  10: 'chapter1-4',
  11: 'chapter1-5',
  12: 'chapter1-6',
  13: 'chapter1-7',
  14: 'chapter1-8',
  15: 'chapter1-9',
  16: 'chapter2',
  17: 'chapter2-1',
  18: 'chapter2-2',
  19: 'chapter2-3',
  20: 'chapter2-4',
  21: 'chapter2-5',
  22: 'chapter2-6',
  23: 'chapter2-7',
  24: 'chapter2-8',
  25: 'chapter3',
  26: 'chapter3-1',
  27: 'chapter3-2',
  28: 'chapter3-3',
  29: 'chapter3-4',
  30: 'chapter3-5',
  31: 'chapter3-6',
  32: 'chapter3-7',
  33: 'chapter3-8',
  34: 'chapter3-9',
  35: 'chapter4',
  36: 'chapter4-1',
  37: 'chapter4-2',
  38: 'chapter4-3',
  39: 'chapter4-4',
  40: 'chapter4-5',
  41: 'chapter4-6',
  42: 'chapter4-7',
  43: 'chapter4-8',
  44: 'chapter5',
  45: 'chapter5-1',
  46: 'chapter5-2',
  47: 'chapter5-3',
  48: 'chapter5-4',
  49: 'chapter5-5',
  50: 'chapter5-6',
  51: 'chapter5-7',
  52: 'chapter5-8',
  53: 'chapter5-9',
  54: 'chapter5-10',
  55: 'chapter6',
  56: 'chapter6-1',
  57: 'chapter6-2',
  58: 'chapter6-3',
  59: 'chapter6-4',
  60: 'chapter6-5',
  61: 'chapter6-6',
  62: 'chapter6-7',
  63: 'chapter6-8',
  64: 'chapter6-9',
  65: 'chapter6-10',
  66: 'chapter6-11',
  67: 'chapter6-12',
  68: 'chapter6-13',
  69: 'back-cover',
  70: 'gallery',
};
export default pageMap;
