import React, { useEffect, useState } from 'react';
import Image from '../Image';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import pageMap from '../../constants/pages';

const GalleryPic = ({ number, setPage }) => {
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const pages = pageMap;

  useEffect(() => {
    const time = number * 120 + 120;
    setTimeout(() => {
      setDisplay(true);
    }, time);
  }, []);
  return (
    <div
      className="gallery-pic-wrapper"
      onClick={() => {
        navigate(`/${pages[number + 1]}`);
      }}
    >
      {display && <Image name={`${number}.jpg`} className="gallery-pic" />}
    </div>
  );
};

export default GalleryPic;
