import React, { useEffect, useState } from 'react';
import Image from '../../Image';
import './styles.css';
import '../../../App.css';
import { useLocation } from 'react-router-dom';

const ImageContainer = ({
  page,
  setPage,
  showPicture,
  setShowPicture,
  animationClass,
  setAnimationClass,
}) => {
  const [backgroundColor, setBackgroundColor] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    setIsVideoPlaying(false);
  }, [page]);

  const location = useLocation();
  const pageName = location.pathname.substring(1);

  useEffect(() => {
    setBackgroundColor(
      pageName === 'chapter4-2' || pageName === 'chapter4-7' ? true : false,
    );
  }, [pageName]);

  useEffect(() => {
    const videoPages = [13, 14, 42, 61, 63];
    setIsVideoActive(videoPages.includes(page));
  }, [page]);

  return (
    <div className="image-container">
      <div
        className="prev-page-box inicio-prev-page"
        onClick={() => {
          setShowPicture(false);
          setTimeout(() => {
            setPage((prev) => prev - 1);
          }, 500);
          setAnimationClass('image-piece-show-');
        }}
      >
        <Image name="arrow-white.png" className="page-2-left-arrow" />{' '}
        {/* This is the arrow that goes to the previous page */}
      </div>

      <div
        className="next-page-box inicio-next-page"
        onClick={() => {
          setShowPicture(false);
          setTimeout(() => {
            setPage((prev) => prev + 1);
          }, 500);
          setAnimationClass('image-piece-show-left-');
        }}
      >
        <Image name="arrow-white.png" className="page-2-arrow" />{' '}
        {/* This is the arrow that goes to the next page */}
      </div>

      {page !== 1 && (
        <Image
          style={
            backgroundColor ? { backgroundColor: 'white', width: '100%' } : null
          }
          name={`${page - 1}.jpg` || `${page - 1}.png`}
          className={`image-piece ${animationClass}${showPicture} ${
            isVideoActive && isVideoPlaying && 'video-active'
          }`}
          onLoad={() => {
            setShowPicture(true);
          }}
        />
      )}

      {page === 13 && (
        <iframe
          src="https://player.vimeo.com/video/485602993?&autoplay=1&loop=1&muted=1&controls=0"
          className={`video ${animationClass}${showPicture} video-safari`}
          frameborder="0"
          allow="autoplay; fullscreen"
          onLoad={() => {
            setShowPicture(true);
            setIsVideoPlaying(true);
          }}
          title="video"
        ></iframe>
      )}
      {page === 14 && (
        <iframe
          src="https://player.vimeo.com/video/485603368?&autoplay=1&loop=1&muted=1&controls=0"
          className={`video ${animationClass}${showPicture} video-safari`}
          frameborder="0"
          allow="autoplay; fullscreen"
          title="video"
          onLoad={() => {
            setShowPicture(true);
            setIsVideoPlaying(true);
          }}
        ></iframe>
      )}
      {page === 42 && (
        <iframe
          src="https://player.vimeo.com/video/852410511?h=4c0424ee97&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&loop=1&muted=1&controls=0"
          className={`video ${animationClass}${showPicture} video-safari`}
          frameborder="0"
          allow="autoplay; fullscreen"
          title="video"
          onLoad={() => {
            setShowPicture(true);
            setIsVideoPlaying(true);
          }}
        ></iframe>
      )}
      {page === 61 && (
        <iframe
          src="https://player.vimeo.com/video/988450781?h=dbc3a5f259&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&loop=1&muted=1&controls=0"
          className={`video ${animationClass}${showPicture} video-safari`}
          frameborder="0"
          allow="autoplay; fullscreen"
          title="video"
          onLoad={() => {
            setShowPicture(true);
            setIsVideoPlaying(true);
          }}
        ></iframe>
      )}
      {page === 63 && (
        <iframe
          src="https://player.vimeo.com/video/989658200?h=18d9c0826a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&loop=1&muted=1&controls=0"
          className={`video ${animationClass}${showPicture} video-safari`}
          frameborder="0"
          allow="autoplay; fullscreen"
          title="video"
          onLoad={() => {
            setShowPicture(true);
            setIsVideoPlaying(true);
          }}
        ></iframe>
      )}
    </div>
  );
};

export default ImageContainer;
