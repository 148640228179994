import React from 'react';
import Image from '../../../Image';

import './styles.css';

const SmallPic = ({ picNumber, onClick, page, keyIndex }) => {
  const getChapterLineStyle = () => {
    if (page < picNumber + 1) {
      return 'chapter-line chapter-line-inactive';
    }
    if (page > picNumber) {
      return 'chapter-line chapter-line-active';
    }
  };

  const getChapterHalfLineStyle = () => {
    if (page < picNumber + 1) {
      return 'chapter-half-line chapter-half-line-inactive';
    }
    if (page > picNumber) {
      return 'chapter-half-line chapter-half-line-active';
    }
  };

  const getChapterSquareStyle = () => {
    if (page < picNumber + 1) {
      return 'chapter-square';
    }
    if (page > picNumber) {
      return 'chapter-square chapter-square-active';
    }
  };

  return (
    <div className="small-pic-area" onClick={onClick} key={keyIndex}>
      {picNumber !== 5 && picNumber !== 15 && picNumber !== 24 && (
        <div className="chapter-line-wrapper">
          <div className={getChapterLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}
      {picNumber === 5 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 5 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-title">
                Using custom tipography to build a bold brand.
              </div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}
      {picNumber === 15 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 15 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-title">
                Borrowing for design is not stealing.
              </div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}
      {picNumber === 24 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 24 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-title">Telling a with design.</div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}
      {picNumber === 34 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 34 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-title">
                Leveraging a logo for design impact.
              </div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}

      {picNumber === 43 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 43 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-five-title">
                Design with legacy.
              </div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}

      {picNumber === 54 && (
        <div className="chapter-line-half-wrapper">
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
          {picNumber === 54 && (
            <div className={getChapterSquareStyle()}>
              <div className="chapter-square-five-title">
                Design for an ideal audience
              </div>
            </div>
          )}
          <div className={getChapterHalfLineStyle()}>
            <div className="chapter-line-fill"></div>
          </div>
        </div>
      )}
      <div onClick={onClick} className="small-pick-clicker">
        <Image name={`mini${picNumber}.jpg`} className="small-pic" />
      </div>
    </div>
  );
};

export default SmallPic;
