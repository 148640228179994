import React, { useState } from 'react';
import SmallPic from './SmallPic';
import Share from './Share';
import Image from '../../Image';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const smallPics = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67,
];

const LowerPanel = ({ setPage, page, setShowPicture }) => {
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  function toggleFullScreen(elem) {
    if (
      (document.fullScreenElement !== undefined &&
        document.fullScreenElement === null) ||
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement === null) ||
      (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
      (document.webkitIsFullScreen !== undefined &&
        !document.webkitIsFullScreen)
    ) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  const transition = (num) => {
    if (page === num + 1) {
      setShowPicture(false);
      setTimeout(() => {
        setShowPicture(true);
      }, 500);
    } else {
      setShowPicture(false);
      setTimeout(() => {
        setPage(num + 1);
      }, 500);
    }
  };

  return (
    <div className="lower-panel-container">
      {showShare && <Share />}
      <div className="lower-button-box">
        <div
          className="lower-pannel-button"
          onClick={() => {
            navigate('/');
            setPage(1);
          }}
        >
          <Image name="home.png" className="share-icon-panel" />
        </div>
        <div
          className="lower-pannel-button"
          onClick={() => {
            navigate('/gallery');
          }}
        >
          <Image name="grid.png" className="share-icon-panel" />
        </div>
        <div
          className="lower-pannel-button"
          onClick={() => setShowShare((prev) => !prev)}
        >
          <Image name="shareArrow.png" className="share-icon-panel" />
        </div>
        <div
          className="lower-pannel-button"
          onClick={() => toggleFullScreen(document.body)}
        >
          <Image name="fullScreen.png" className="share-icon-panel" />
        </div>
      </div>
      <div className="small-pic-container">
        {smallPics.map((num, i) => {
          return (
            <SmallPic
              page={page}
              picNumber={num}
              keyIndex={i}
              onClick={() => transition(num)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LowerPanel;
