import React, { useEffect, useState } from 'react';
import GalleryPic from './GalleryPic';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const pictures = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67,
];

const Gallery = () => {
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 6000);
  }, []);
  return (
    <div>
      <div
        className="gallery-back"
        onClick={() => {
          navigate('/index');
        }}
      >
        Back to Book
        <FontAwesomeIcon icon={faArrowUpLong} className="gallery-arrow" />
      </div>
      <div className="gallery-wrapper">
        <div className="gallery-grid">
          {pictures.map((e) => {
            return <GalleryPic number={e} />;
          })}
        </div>
      </div>
      <div
        className="gallery-back-mobile"
        onClick={() => {
          navigate('/index');
        }}
      >
        Back to Book
        <FontAwesomeIcon icon={faArrowUpLong} className="gallery-arrow" />
      </div>
    </div>
  );
};

export default Gallery;
