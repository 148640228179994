import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Share = () => {
  const link = 'https://designthoughts.superestudio.tv/';

  const copy = () => {
    var text = link;
    navigator.clipboard.writeText(text).then(
      function () {
        alert('¡Link al Ebook copiado al portapapeles!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  const shareLinkedin = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${link}`);
  };

  return (
    <div className="share-wrapper">
      <FontAwesomeIcon icon={faLink} className="share-icon" onClick={copy} />
      <img
        alt="linkedin"
        src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/linkedin-app-white-icon.png"
        className="linkedin-icon"
        onClick={shareLinkedin}
      />
    </div>
  );
};

export default Share;
