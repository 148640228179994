import { useEffect, useState } from 'react';
import './App.css';
import './components/ControlPanel/styles.css';
import ControlPanel from './components/ControlPanel';
import useWindowDimensions from './hooks/useWindowDimensions';
import Inicio from './screens/Inicio';
import ReactGA from 'react-ga';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import pageMap from './constants/pages';

const trackingId = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(trackingId);

function Component({ pageParam }) {
  const [animationClass, setAnimationClass] = useState(
    'image-piece-show-left-',
  );
  const [page, setPage] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const [showPicture, setShowPicture] = useState(false);
  const [prevPage, setPrevPage] = useState(null);

  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const pages = pageMap;
  const location = useLocation();
  const pageName = location.pathname.substring(1);

  const pageMatch = parseInt(
    Object.keys(pages).find((key) => pages[key] === pageName),
  );

  useEffect(() => {
    window.scrollTo(1, 0);
  }, []);

  useEffect(() => {
    if (page < 69) {
      setPrevPage(page);
    }
  }, [page]);

  useEffect(() => {
    ReactGA.pageview();
  }, []);

  useEffect(() => {
    if (pageParam) {
      setPage(pageParam);
    }
  }, [pageParam]);

  useEffect(() => {
    if (pageName) {
      setPage(pageMatch);
    }
  }, []);

  useEffect(() => {
    if (page <= 69) {
      if (page !== 1) {
        navigate(`/${pages[page]}`);
      }
    } else {
      navigate(`/${prevPage}`);
    }
  }, [page]);

  return (
    <div className="App">
      <header className="App-header"></header>

      {page === 1 && (
        <div className="pages-wrapper">
          <div className="inicio-wrapper">
            <Inicio setPage={setPage} page={page} />
          </div>
        </div>
      )}

      {page !== 1 && page < 69 && (
        <div className="pages-wrapper">
          <ControlPanel
            animationClass={animationClass}
            setAnimationClass={setAnimationClass}
            setPage={setPage}
            page={page}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            showPicture={showPicture}
            setShowPicture={setShowPicture}
          />
        </div>
      )}

      {page === 69 && setPage(2)}

      {pageName === 'index' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="chapter-box chapter-0-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(5);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-1-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(6);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-2-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(16);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-3-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(25);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-4-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(35);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-5-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(44);
                }, 500);
              }}
            ></div>
            <div
              className="chapter-box chapter-6-box"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage(55);
                }, 500);
              }}
            ></div>
            <div
              className="control-panel-box"
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            />
          </div>
        </div>
      )}
      {pageName === 'chapter1-9' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-11-box"
              onClick={() =>
                window.open('https://www.superestudio.tv/case-studies/espn/')
              }
            ></div>
          </div>
        </div>
      )}
      {pageName === 'chapter2-8' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-18-box"
              onClick={() =>
                window.open('https://www.superestudio.tv/case-studies/x-files/')
              }
            ></div>
          </div>
        </div>
      )}
      {pageName === 'chapter3-9' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-33-box"
              onClick={() =>
                window.open(
                  'https://www.superestudio.tv/case-studies/la-corazonada/',
                )
              }
            ></div>
          </div>
        </div>
      )}
      {pageName === 'chapter4-8' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-43-box"
              onClick={() =>
                window.open('https://www.superestudio.tv/case-studies/tfx/')
              }
            ></div>
          </div>
        </div>
      )}
      {pageName === 'chapter5-10' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-55-box"
              onClick={() =>
                window.open('https://www.superestudio.tv/case-studies/hbo+/')
              }
            ></div>
          </div>
        </div>
      )}

      {pageName === 'chapter6-13' && (
        <div className="link-wrapper-wrapper">
          <div
            className="link-wrapper"
            style={{
              width: width > height * 1.78 ? `${height * 1.78}px` : '100vw',
              height: height > width * 0.5625 ? `${width * 0.5625}px` : '100vh',
            }}
          >
            <div
              className="prev-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev - 1);
                }, 500);
                setAnimationClass('image-piece-show-');
              }}
            />
            <div
              className="next-page-box-11"
              onClick={() => {
                setShowPicture(false);
                setTimeout(() => {
                  setPage((prev) => prev + 1);
                }, 500);
                setAnimationClass('image-piece-show-left-');
              }}
            />
            <div
              className="page-69-box"
              onClick={() =>
                window.open('https://superestudio.tv/case-studies/dmax-ids/')
              }
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

function App() {
  const { pageParam } = useParams();
  return <Component pageParam={pageParam ? parseInt(pageParam) : 0} />;
}

export default App;
