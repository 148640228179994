import React, { useEffect, useRef } from "react";
import "./styles.css";
import ReactPlayer from "react-player";
import { isSafari } from "react-device-detect";
import cover from "./cover.mp4";

const Inicio = ({ page, setPage }) => {
  const videoParentRef = useRef();

  useEffect(() => {
    if (isSafari && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); 
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
              });
          }
        }, 0);
      }
    }
  }, []);

  var EmbedVideo = function (props) {
    return (
      <div
        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
              src="${props.src}"
              class="${props.className}"
            >
              <source src="${props.src}" type="video/mp4" />
            </video>
            `
        }}
      />
    );
  };

  return (
    <div onClick={() => setPage(2)} className="inicio-wrapper">
      {isSafari ? (
        <EmbedVideo src={cover} className="video video-safari" />
      ) : (
        <ReactPlayer
          url={cover}
          playing={true}
          loop={true}
          muted={true}
          controls={false}
          width="100%"
          height="100%"
          className="cover-video"
        >
          <source src={cover} type="video/mp4" />
        </ReactPlayer>
      )}
    </div>
  );
};

export default Inicio;
